import {
    Box,
    Typography,
    detailBillingName,
    formatterPeso,
} from "@enerbit/base";
import { ContainerCardRowConcepts } from "../../styles/CardComponent";

export interface SettlementCardRowProps {
    title: string;
    color?: string;
    backgroundColor?: string;
    fontWeight?: number;
    minuend?: number;
    subtrahend?: number;
    difference?: number;
    showReactiveTable?: boolean;
    mMultiplier?: boolean;
}

export const ReSettlementCardRowConcepts = ({
    title,
    minuend,
    subtrahend,
    difference,
    showReactiveTable,
    mMultiplier,
}: SettlementCardRowProps) => {
    console.log(mMultiplier);
    return (
        <ContainerCardRowConcepts>
            <Box
                style={{
                    width: "40%",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography sx={{ fontSize: "14px", color: "#667085" }}>
                    {showReactiveTable ? title : detailBillingName[title]}
                </Typography>
            </Box>
            <Box
                style={{ width: "20%" }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography sx={{ color: "#667085" }}>
                    {mMultiplier
                        ? subtrahend
                        : formatterPeso.format(subtrahend ?? 0)}
                </Typography>
            </Box>
            <Box
                style={{ width: "20%" }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography
                    sx={{
                        marginLeft: "1rem",
                        color: "#667085",
                    }}
                >
                    {mMultiplier ? minuend : formatterPeso.format(minuend ?? 0)}
                </Typography>
            </Box>

            <Box
                style={{ width: "20%" }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography
                    sx={{
                        color: difference! < 0 ? "#007F61" : "#D53C32",
                        fontWeight: "700",
                        marginLeft: "1rem",
                    }}
                >
                    {mMultiplier
                        ? difference
                        : formatterPeso.format(difference ?? 0)}
                </Typography>
            </Box>
        </ContainerCardRowConcepts>
    );
};
