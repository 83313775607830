import { Box, ServiceTypeName } from "@enerbit/base";
import { useInvoiceMemoStore } from "../../../../store/store";
import { EssInvoiceMemoAgreement } from "./EssInvoiceMemoAgreement";
import { EnerbitInvoiceMemoAgreement } from "./EnerbitInvoiceMemoAgreement";

export const DetailInvoiceMemoAgreement = () => {
  const { invoiceMemoTypeName: invoiceTypeName } = useInvoiceMemoStore(
    (state) => ({
      invoiceMemoTypeName: state.invoiceMemoTypeName,
    })
  );

  return (
    <>
      {invoiceTypeName == ServiceTypeName.electricitySupplyServices && (
        <Box>
          <EssInvoiceMemoAgreement />
        </Box>
      )}
      {invoiceTypeName == ServiceTypeName.enerbitElectricitySupplyServices && (
        <Box>
          <EnerbitInvoiceMemoAgreement />
        </Box>
      )}
    </>
  );
};
