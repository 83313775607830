import { ServiceTypeName, ThemeConfig, initEnviroment } from "@enerbit/base";
import "./assets/css/login.scss";

import "./styles/App.scss";
import { GetDialogByInvoiceMemo } from "./pages/InvoiceMemo";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
});

export default function Root({
  invoiceMemoId,
  serviceTypeName,
}: {
  invoiceMemoId: string;
  serviceTypeName: ServiceTypeName;
}) {
  return (
    <ThemeConfig>
      <GetDialogByInvoiceMemo
        invoiceMemoId={invoiceMemoId}
        serviceTypeName={serviceTypeName}
      />
    </ThemeConfig>
  );
}
