import { ServiceTypeName } from "@enerbit/base";
import { useInvoiceMemoStore } from "../store/store";
import { useEffect } from "react";
import InvoiceMemoDialog from "./InvoiceMemoDialog/InvoiceMemoDialog";

const BASE_PATH = "/electricity-supply-service/billing/";

export const GetDialogByInvoiceMemo = ({
    serviceTypeName,
    invoiceMemoId,
}: {
    serviceTypeName: ServiceTypeName;
    invoiceMemoId: string;
}): JSX.Element => {
    const {
        setInvoiceTypeName,
        getMemoDetail,
        setInvoiceMemoName,
        setInvoiceMemoId,
    } = useInvoiceMemoStore();

    useEffect(() => {
        setInvoiceTypeName(serviceTypeName);
        setInvoiceMemoId(invoiceMemoId);
        if (serviceTypeName === ServiceTypeName.electricitySupplyServices) {
            getMemoDetail({
                invoiceMemoId: invoiceMemoId,
                path: BASE_PATH + "electricity-supply/memo/",
            });
            setInvoiceMemoName("Detalle reliquidación de energía");
        }

        if (
            serviceTypeName === ServiceTypeName.enerbitElectricitySupplyServices
        ) {
            getMemoDetail({
                invoiceMemoId: invoiceMemoId,
                path: BASE_PATH + "enerbit-electricity-supply/memo/",
            });

            setInvoiceMemoName("Detalle reliquidación servicios enerBit");
        }
    }, [serviceTypeName, invoiceMemoId]);

    return <InvoiceMemoDialog />;
};
