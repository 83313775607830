import {
    Box,
    CircularProgress,
    ErrorOutlineIcon,
    Grid,
    Typography,
    enerbitColors,
    formatterPeso,
} from "@enerbit/base";
import {
    CardReSettlementAdjustment,
    CardReSettlementDetailObservation,
} from "../../styles/CardComponent";
import { ContainerCardAdjust } from "./ContainerCardAdjust";
import { useInvoiceMemoStore } from "../../../../../store/store";
import { useEffect } from "react";

export const ContainerHeaderCards = () => {
    const {
        invoiceMemo,
        getResettlementExplain,
        isLoadingResettlementExplainRequest,
        memoResettlementExplain,
    } = useInvoiceMemoStore();

    useEffect(() => {
        getResettlementExplain({
            invoiceMemoId: invoiceMemo.invoice_memo?.id ?? "",
        });
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4.56}>
                {isLoadingResettlementExplainRequest ? (
                    <>
                        <Box
                            display="flex"
                            gap="1rem"
                            sx={{
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    </>
                ) : (
                    <>
                        <CardReSettlementAdjustment>
                            <Box className="Card-memo-icon-adjustment">
                                <ErrorOutlineIcon sx={{ color: "white" }} />
                            </Box>
                            <Box>
                                <Typography
                                    color={enerbitColors.primary.main}
                                    fontSize={16}
                                    fontWeight={700}
                                >
                                    Observaciones de la reliquidación
                                </Typography>
                                <Typography
                                    color={enerbitColors.neutral.main}
                                    sx={{ marginTop: "0.2rem" }}
                                >
                                    {memoResettlementExplain}
                                </Typography>
                            </Box>
                        </CardReSettlementAdjustment>
                    </>
                )}
            </Grid>
            <Grid item xs={12} md={2.48}>
                <CardReSettlementDetailObservation>
                    <ContainerCardAdjust
                        title={
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[700],
                                    fontWeight: "700",
                                    fontSize: 16,
                                }}
                            >
                                Factura inicial
                            </Typography>
                        }
                        value={
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[600],
                                    fontSize: "16px",
                                }}
                            >
                                {formatterPeso.format(
                                    invoiceMemo.invoice_delta
                                        ?.parent_invoice_amount as number,
                                )}
                            </Typography>
                        }
                        showButtonDownload={true}
                        isMemo={false}
                    />
                </CardReSettlementDetailObservation>
            </Grid>
            <Grid item xs={12} md={2.48}>
                <CardReSettlementDetailObservation>
                    <ContainerCardAdjust
                        title={
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[700],
                                    fontWeight: "700",
                                    fontSize: 16,
                                }}
                            >
                                Reliquidación
                            </Typography>
                        }
                        value={
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral[600],
                                    fontSize: "16px",
                                }}
                            >
                                {formatterPeso.format(
                                    invoiceMemo.invoice_delta
                                        ?.resettlement_amount as number,
                                )}
                            </Typography>
                        }
                        showButtonDownload={false}
                    />
                </CardReSettlementDetailObservation>
            </Grid>
            <Grid item xs={12} md={2.48}>
                <CardReSettlementDetailObservation
                    sx={{
                        background:
                            (invoiceMemo.invoice_delta?.memo_amount ?? 0) < 0
                                ? "#CAEFDE"
                                : enerbitColors.error[200],
                    }}
                >
                    <ContainerCardAdjust
                        title={
                            <Typography
                                sx={{
                                    color:
                                        (invoiceMemo.invoice_delta
                                            ?.memo_amount ?? 0) < 0
                                            ? "#007F61"
                                            : "#D53C32",
                                    fontWeight: "700",
                                    fontSize: 16,
                                }}
                            >
                                Diferencia
                            </Typography>
                        }
                        value={
                            <>
                                <Typography
                                    sx={{
                                        color:
                                            (invoiceMemo.invoice_delta
                                                ?.memo_amount ?? 0) < 0
                                                ? "#007F61"
                                                : "#D53C32",
                                        fontSize: "16px",
                                    }}
                                >
                                    {formatterPeso.format(
                                        invoiceMemo.invoice_delta
                                            ?.memo_amount as number,
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        color:
                                            (invoiceMemo.invoice_delta
                                                ?.memo_amount ?? 0) < 0
                                                ? "#007F61"
                                                : "#D53C32",
                                        fontSize: "12px",
                                    }}
                                >
                                    {(invoiceMemo.invoice_delta?.memo_amount ??
                                        0) < 0
                                        ? "Nota crédito"
                                        : "Nota débito"}
                                </Typography>
                            </>
                        }
                        showButtonDownload={true}
                        isMemo={true}
                    />
                </CardReSettlementDetailObservation>
            </Grid>
        </Grid>
    );
};
